import axios from "axios";

import { GET_ALL_CATEGORIES, GET_CATEGORIES_FAIL, GET_CATEGORIES_SUCCESS } from "../constants/categoryConstants";


export const getCategoriesAction=(token)=>async (dispatch)=>{
    
	
    try {
		dispatch({type:GET_ALL_CATEGORIES});
		const response=await axios.get(process.env.REACT_APP_API_URL+"/api/categories", {
			headers:{Authorization: "Bearer " + token}
		});
		dispatch({type:GET_CATEGORIES_SUCCESS, payload:response.data});
		
	} catch (exception) {
        
		dispatch({type:GET_CATEGORIES_FAIL,payload:"Valamilyen hiba lépett fel a betöltés során, próbáld meg később"});
    }
} 