import classNames from "classnames";
import React from "react";
import ButtonContainer from "./ButtonContainer";

export default function SmallTaskBox({
	task,
	editModalShow,
	commentModalShow,
	addToDailyHandle,
	deleteTaskHandler,
}) {
	return (
		<div className={classNames("small-task-box", "priority-" + task.priority)}>
			<span
				className={classNames("task-status", {
					new: task.status.name === "Új",
					continue: task.status.name === "Folytatandó",
					end: task.status.name === "Befejezett",
				})}
			>
				{task.status.name}
			</span>
			<h2>{task.title}</h2>
			<div className="category-and-buttons-container">
				<span>{task.category.title}</span>
				<ButtonContainer
					task={task}
					editModalShow={editModalShow}
					commentModalShow={commentModalShow}
					addToDailyHandle={addToDailyHandle}
					deleteTaskHandler={deleteTaskHandler}
				></ButtonContainer>
			</div>
		</div>
	);
}
