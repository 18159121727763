import {
	STATUSES_FAIL,
	STATUSES_LOADING,
	STATUSES_SUCCESS,
} from "../constants/statusConstants";

export const statusReducer = (state = {}, action) => {
	switch (action.type) {
		case STATUSES_LOADING:
			return { ...state, loading: true };
		case STATUSES_SUCCESS:
			return {
				...state,
				loading: false,
				success: true,
				statuses: action.payload,
			};
		case STATUSES_FAIL:
			return {
				...state,
				loading: false,
				haveError: true,
				error: action.payload,
			};
		default:
			return state;
	}
};

export const getStatusesLoading = (state) =>
	state ? (state.status ? state.loading : null) : null;
export const getStatusesHaveError = (state) =>
	state ? (state.status ? state.status.haveError : null) : null;
export const getStatusesError = (state) =>
	state ? (state.status ? state.status.error : null) : null;
export const getStatuses = (state) =>
	state ? (state.status ? state.status.statuses : null) : null;
