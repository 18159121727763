import axios from "axios";
import { STATUSES_FAIL, STATUSES_LOADING, STATUSES_SUCCESS } from "../constants/statusConstants";


export const getStatusesAction = (token) => async (dispatch) => {
    
	dispatch({ type: STATUSES_LOADING });
	try {
		const {data} = await axios.get(process.env.REACT_APP_API_URL+"/api/statuses",{headers: { 'Content-Type': 'application/json',Authorization: 'Bearer '+token}});
       
        dispatch({type:STATUSES_SUCCESS,payload:data});
	} catch (e) {
        dispatch({type: STATUSES_FAIL,payload:"Valamilyen hiba lépett fel a státuszok betöltése közben"});
    }
};
