import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import AuthButtons from "./AuthButtons";
import { useSelector } from "react-redux";
import { getLoggedIn } from "../store/reducers/authReducer";
export default function SmallNavButtons({windowSize,onLogout,setShowSettings,setShowCreateTaskModal}) {
    const loggedIn = useSelector(getLoggedIn);
	return (
		<>
			{loggedIn ? (
				<>
					<button onClick={()=>setShowCreateTaskModal(true)}>
						<AddIcon></AddIcon>
					</button>
					<button onClick={()=>setShowSettings(true)}>
						<SettingsIcon></SettingsIcon>
					</button>
					<button onClick={()=>onLogout()}>
						<LogoutIcon></LogoutIcon>
					</button>
				</>
			) : (
				<AuthButtons windowSize={windowSize}></AuthButtons>
			)}
		</>
	);
}
