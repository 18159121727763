import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { filterAction } from "../store/actions/filterAction";

import { removeFilterAction } from "../store/actions/removeFilterAction";
import { getToken } from "../store/reducers/authReducer";
import { getCategories } from "../store/reducers/categoryReducer";
import {
	getCategory,
	getCreatedAtOrder,
	getPriority,
	getPriorityOrder,
	getStatus,
	getTitle,
	getTitleOrder,
} from "../store/reducers/orderAndFilterReducer";
import {
	getStatuses,
	getStatusesError,
	getStatusesHaveError,
	getStatusesLoading,
} from "../store/reducers/statusReducer";

export default function Filter({ setFilterOpen, onChange }) {
	
	const dispatch = useDispatch();
	const statuses = useSelector(getStatuses);
	const statusesLoading = useSelector(getStatusesLoading);
	const statusesHaveError = useSelector(getStatusesHaveError);
	const statusesError = useSelector(getStatusesError);

	const categories = useSelector(getCategories);
	const status = useSelector(getStatus);
	const priority = useSelector(getPriority);
	const category = useSelector(getCategory);
	const title = useSelector(getTitle);
	const priorityOrder = useSelector(getPriorityOrder);
	const titleOrder = useSelector(getTitleOrder);
	const createdAtOrder = useSelector(getCreatedAtOrder);
	const token = useSelector(getToken);

	useEffect(() => {
		dispatch(
			filterAction(
				title,
				status,
				priority,
				category,
				titleOrder,
				priorityOrder,
				createdAtOrder,
				token
			)
		);
	}, [
		category,
		createdAtOrder,
		dispatch,
		priority,
		priorityOrder,
		status,
		title,
		titleOrder,
		token,
	]);

	const handleRemoveFilter = () => {
		dispatch(removeFilterAction(token));
		
		setTimeout(
			() =>{
				setFilterOpen(false);
			},
			500
		);
		
	};

	return (
		<div className="filter-container">
			<Form>
				<Form.Group>
					<Form.Label>Cím</Form.Label>
					<Form.Control
						value={title}
						onChange={(e) => onChange(e, "title")}
					></Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Label>Feladat státusz</Form.Label>
					{!statusesLoading ? (
						statusesHaveError ? (
							statusesError
						) : (
							<Form.Select
								value={status}
								onChange={(e) => onChange(e, "status")}
							>
								<option></option>
								{statuses.map((status) => (
									<option key={status.id} value={status.id}>
										{status.name}
									</option>
								))}
							</Form.Select>
						)
					) : (
						""
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>Feladat prioritás</Form.Label>
					<Form.Control
						min={1}
						max={5}
						value={priority}
						type="number"
						onChange={(e) => onChange(e, "priority")}
					></Form.Control>
					<Form.Text className="text-muted">
						1 és 5 közötti értéket adjon meg, ilyen prioritási értékek vannak
					</Form.Text>
				</Form.Group>

				<Form.Group>
					<Form.Label>Kategória</Form.Label>
					<Form.Select
						value={category}
						onChange={(e) => onChange(e, "category")}
					>
						<option></option>
						{categories.map((category) => (
							<option key={category.id} value={category.id}>
								{category.title}
							</option>
						))}
					</Form.Select>
				</Form.Group>
				<span
					style={{
						margin: "10px 0",
						display: "block",
						fontWeight: "bold",
						fontSize: "1.2rem",
					}}
				>
					Rendezés
				</span>
				<Form.Group>
					<Form.Label>Prioritás szerint</Form.Label>
					<Form.Select
						value={priorityOrder}
						onChange={(e) => onChange(e, "priorityOrder")}
					>
						<option></option>
						<option value="ASC">Növekvő</option>
						<option value="DESC">Csökkenő</option>
					</Form.Select>
				</Form.Group>
				<Form.Group>
					<Form.Label>Cím szerint(Abc sorrend)</Form.Label>
					<Form.Select
						value={titleOrder}
						onChange={(e) => onChange(e, "titleOrder")}
					>
						<option></option>
						<option value="ASC">Növekvő</option>
						<option value="DESC">Csökkenő</option>
					</Form.Select>
				</Form.Group>
				<Form.Group>
					<Form.Label>Létrehozás ideje szerint</Form.Label>
					<Form.Select
						value={createdAtOrder}
						onChange={(e) => onChange(e, "createdAtOrder")}
					>
						<option></option>
						<option value="ASC">Növekvő</option>
						<option value="DESC">Csökkenő</option>
					</Form.Select>
				</Form.Group>
			</Form>

			<button
				onClick={() => handleRemoveFilter()}
				className="form-button-delete"
			>
				Szűrési feltételek törlése
			</button>
		</div>
	);
}
