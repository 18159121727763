export const LOGIN_START="LOGIN";
export const LOGIN_SUCCESS="LOGIN SUCCESS";
export const LOGIN_FAILURE="LOGIN FAILURE";
export const RESET_LOGIN_DATA="RESET_LOGIN_DATA";

export const REGISTER_START="REGISTER_START";
export const REGISTER_SUCCESS="REGISTER_SUCCESS";
export const REGISTER_FAILURE="REGISTER_FAILURE";
export const RESET_REGISTER_DATA="RESET_REGISTER_DATA"

export const LOG_OUT="LOG_OUT";
