import {
	ADD_TO_DAILY,
	ADD_TO_DAILY_ERROR,
	ADD_TO_DAILY_SUCCESS,
	ALL_TASKS_ERROR,
	ALL_TASKS_LOADED,
	ALL_TASKS_LOADING,
	CREATE_TASK_FAIL,
	CREATE_TASK_START,
	CREATE_TASK_SUCCESS,
	DAILY_TASKS_FAIL,
	DAILY_TASKS_LOADED,
	DAILY_TASKS_LOADING,
	DELETE_TASK,
	DELETE_TASK_FAIL,
	DELETE_TASK_SUCCESS,
	ENDED_TASKS_ERROR,
	ENDED_TASKS_LOADED,
	ENDED_TASKS_LOADING,
	REMOVE_ADD_TO_DAILY_DATA,
	REMOVE_CURRENT_TASK,
	REMOVE_DELETE_DATA,
	REMOVE_FROM_DAILY,
	REMOVE_FROM_DAILY_ERROR,
	REMOVE_FROM_DAILY_SUCCESS,
	REMOVE_REMOVE_FROM_DAILY_DATA,
	REMOVE_SET_STATUS_DATA,
	REMOVE_UPDATE_TASK_DATA,
	SET_CURRENT_TASK,
	SET_STATUS,
	SET_STATUS_ERROR,
	SET_STATUS_SUCCESS,
	UPDATE_TASK,
	UPDATE_TASK_FAIL,
	UPDATE_TASK_SUCCESS,
} from "../constants/taskConstants";

export const taskReducer = (state = {}, action) => {
	switch (action.type) {
		case ALL_TASKS_LOADING:
			return { ...state, allTasks: { ...state.allTasks, loading: true } };
		case ALL_TASKS_LOADED:
			return {
				...state,
				allTasks: { ...state.allTasks, loading: false, tasks: action.payload },
			};
		case ALL_TASKS_ERROR:
			return {
				...state,
				allTasks: {
					...state.allTasks,
					loading: false,
					haveError: true,
					errorMessage: action.payload,
				},
			};
		case SET_CURRENT_TASK:
			return { ...state, currentTask: action.payload };
		case REMOVE_CURRENT_TASK:
			return { ...state, currentTask: null };
		case DAILY_TASKS_LOADING:
			return {
				...state,
				dailyTasks: {
					...state.dailyTasks,
					loading: true,
				},
			};
		case DAILY_TASKS_LOADED:
			return {
				...state,
				dailyTasks: {
					...state.dailyTasks,
					loading: false,
					success: true,
					tasks: action.payload,
				},
			};
		case DAILY_TASKS_FAIL:
			return {
				...state,
				dailyTasks: {
					...state.dailyTasks,
					loading: false,
					error: action.payload,
				},
			};
		case UPDATE_TASK:
			return { ...state, updateTask: { ...state.updateTask, loading: true } };
		case UPDATE_TASK_SUCCESS:
			return {
				...state,
				updateTask: {
					...state.updateTask,
					loading: false,
					success: true,
					error: false,
				},
			};
		case UPDATE_TASK_FAIL:
			return {
				...state,
				updateTask: {
					...state.updateTask,
					loading: false,
					success: false,
					error: true,
				},
			};
		case REMOVE_UPDATE_TASK_DATA:
			return {
				...state,
				updateTask: { loading: false, success: false, error: false },
			};

		case CREATE_TASK_START:
			return {
				...state,
				createTask: { ...state.createTask, started: true },
			};
		case CREATE_TASK_SUCCESS:
			return {
				...state,
				createTask: { ...state.createTask, end: true },
			};
		case CREATE_TASK_FAIL:
			return {
				...state,
				createTask: { ...state.createTask, end: true },
			};
		case ADD_TO_DAILY:
			return {
				...state,
				addDaily: { ...state.addDaily, started: true },
			};
		case ADD_TO_DAILY_SUCCESS:
			return {
				...state,
				addDaily: { ...state.addDaily, success: true, message: action.payload },
			};
		case ADD_TO_DAILY_ERROR:
			return {
				...state,
				addDaily: {
					...state.addDaily,
					haveError: true,
					message: action.payload,
				},
			};
		case REMOVE_ADD_TO_DAILY_DATA: {
			return {
				...state,
				addDaily: {
					started: false,
					sucess: false,
					haveError: false,
					message: null,
				},
			};
		}
		case REMOVE_FROM_DAILY:
			return {
				...state,
				removeDaily: {
					...state.removeDaily,
					started: true,
				},
			};
		case REMOVE_FROM_DAILY_SUCCESS:
			return {
				...state,
				removeDaily: {
					...state.removeDaily,
					success: true,
					message: "Sikeres eltávolítás!",
				},
			};
		case REMOVE_FROM_DAILY_ERROR:
			return {
				...state,
				removeDaily: {
					...state.removeDaily,
					haveError: true,
					message: action.payload,
				},
			};
		case REMOVE_REMOVE_FROM_DAILY_DATA:
			return {
				...state,
				removeDaily: {
					started: false,
					sucess: false,
					haveError: false,
					message: null,
				},
			};
		case SET_STATUS: {
			return {
				...state,
				setStatus: {
					...state.setStatus,
					started: true,
				},
			};
		}
		case SET_STATUS_SUCCESS: {
			return {
				...state,
				setStatus: {
					...state.setStatus,
					success: true,
					message: action.payload,
				},
			};
		}
		case SET_STATUS_ERROR: {
			return {
				...state,
				setStatus: {
					...state.setStatus,
					haveError: true,
					message: action.payload,
				},
			};
		}
		case REMOVE_SET_STATUS_DATA: {
			return {
				...state,
				setStatus: {
					started: false,
					success: false,
					havError: false,
					message: null,
				},
			};
		}
		case ENDED_TASKS_LOADING:
			return {
				...state,
				endedTasks: {
					...state.endedTasks,
					loading: true,
				},
			};
		case ENDED_TASKS_ERROR:
			return {
				...state,
				endedTasks: {
					...state.endedTasks,
					loading: false,
					haveError: true,
					errorMessage: action.payload,
				},
			};
		case ENDED_TASKS_LOADED:
			return {
				...state,
				endedTasks: {
					...state.endedTasks,
					loading: false,
					tasks: action.payload,
				},
			};
		case DELETE_TASK:
			return {
				...state,
				deleteTask: {
					...state.deleteTask,
					loading: true,
				},
			};
		case DELETE_TASK_SUCCESS:
			return {
				...state,
				deleteTask: {
					...state.deleteTask,
					loading: false,
					success: true,
					message: action.payload,
				},
			};
		case DELETE_TASK_FAIL:
			return {
				...state,
				deleteTask: {
					...state.deleteTask,
					loading: false,
					havError: true,
					message: action.payload,
				},
			};
		case REMOVE_DELETE_DATA:
			return {
				...state,
				deleteTask: {
					loading: false,
					success: false,
					haveError: false,
					message: null,
				},
			};
		default:
			return state;
	}
};

export const getAllTasksLoading = (state) =>
	state
		? state.task
			? state.task.allTasks
				? state.task.allTasks.loading
				: null
			: null
		: null;
export const getAllTasks = (state) =>
	state
		? state.task
			? state.task.allTasks
				? state.task.allTasks.tasks
				: null
			: null
		: null;
export const getAllTasksHaveError = (state) =>
	state
		? state.task
			? state.task.allTasks
				? state.task.allTasks.haveError
				: null
			: null
		: null;
export const getAllTasksError = (state) =>
	state
		? state.task
			? state.task.allTasks
				? state.task.allTasks.errorMessage
				: null
			: null
		: null;

export const getCurrentTask = (state) =>
	state ? (state.task ? state.task.currentTask : null) : null;

export const getDailyTasks = (state) =>
	state
		? state.task
			? state.task.dailyTasks
				? state.task.dailyTasks.tasks
				: null
			: null
		: null;

export const getDailyTasksLoading = (state) =>
	state
		? state.task
			? state.task.dailyTasks
				? state.task.dailyTasks.loading
				: null
			: null
		: null;

export const getDailyTasksSuccess = (state) =>
	state
		? state.task
			? state.task.dailyTasks
				? state.task.dailyTasks.success
				: null
			: null
		: null;

export const getDailyTasksError = (state) =>
	state
		? state.task
			? state.task.dailyTasks
				? state.task.dailyTasks.error
				: null
			: null
		: null;

export const getAddDailyStart = (state) =>
	state
		? state.task
			? state.task.addDaily
				? state.task.addDaily.started
				: null
			: null
		: null;

export const getAddDailySuccess = (state) =>
	state
		? state.task
			? state.task.addDaily
				? state.task.addDaily.success
				: null
			: null
		: null;

export const getAddDailyHaveError = (state) =>
	state
		? state.task
			? state.task.addDaily
				? state.task.addDaily.haveError
				: null
			: null
		: null;

export const getAddDailyMessage = (state) =>
	state
		? state.task
			? state.task.addDaily
				? state.task.addDaily.message
				: null
			: null
		: null;

export const getRemoveDailyStart = (state) =>
	state
		? state.task
			? state.task.removeDaily
				? state.task.removeDaily.started
				: null
			: null
		: null;

export const getRemoveDailySuccess = (state) =>
	state
		? state.task
			? state.task.removeDaily
				? state.task.removeDaily.success
				: null
			: null
		: null;

export const getRemoveDailyHaveError = (state) =>
	state
		? state.task
			? state.task.removeDaily
				? state.task.removeDaily.haveError
				: null
			: null
		: null;

export const getRemoveDailyMessage = (state) =>
	state
		? state.task
			? state.task.removeDaily
				? state.task.removeDaily.message
				: null
			: null
		: null;

export const getSetStatusStart = (state) =>
	state
		? state.task
			? state.task.setStatus
				? state.task.setStatus.started
				: null
			: null
		: null;

export const getSetStatusSuccess = (state) =>
	state
		? state.task
			? state.task.setStatus
				? state.task.setStatus.success
				: null
			: null
		: null;

export const getSetStatusHaveError = (state) =>
	state
		? state.task
			? state.task.setStatus
				? state.task.setStatus.haveError
				: null
			: null
		: null;

export const getSetStatusMessage = (state) =>
	state
		? state.task
			? state.task.setStatus
				? state.task.setStatus.message
				: null
			: null
		: null;
export const getEndedTasks = (state) =>
	state
		? state.task
			? state.task.endedTasks
				? state.task.endedTasks.tasks
				: null
			: null
		: null;
export const getEndedTasksHaveError = (state) =>
	state
		? state.task
			? state.task.endedTasks
				? state.task.endedTasks.haveError
				: null
			: null
		: null;

export const getEndedTasksErrorMessage = (state) =>
	state
		? state.task
			? state.task.endedTasks
				? state.task.endedTasks.errorMessage
				: null
			: null
		: null;
export const getEndedTasksLoading = (state) =>
	state
		? state.task
			? state.task.endedTasks
				? state.task.endedTasks.loading
				: null
			: null
		: null;



		export const getDeleteTaskStart = (state) =>
	state
		? state.task
			? state.task.deleteTask
				? state.task.deleteTask.loading
				: null
			: null
		: null;

export const getDeleteTaskSuccess = (state) =>
	state
		? state.task
			? state.task.deleteTask
				? state.task.deleteTask.success
				: null
			: null
		: null;

export const getDeleteTaskHaveError = (state) =>
	state
		? state.task
			? state.task.deleteTask
				? state.task.deleteTask.haveError
				: null
			: null
		: null;

export const getDeleteTaskMessage = (state) =>
	state
		? state.task
			? state.task.deleteTask
				? state.task.deleteTask.message
				: null
			: null
		: null;