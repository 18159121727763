import React, { useState } from "react";
import SmallTaskBox from "./SmallTaskBox";
import { useDispatch, useSelector } from "react-redux";
import {
	getAddDailySuccess,
	getAllTasks,
	getAllTasksLoading,
	getEndedTasks,
	getEndedTasksErrorMessage,
	getEndedTasksHaveError,
	getEndedTasksLoading,
} from "../store/reducers/taskReducer";
import { Spinner } from "react-bootstrap";
import { addTaskToDailyAction } from "../store/actions/addTaskToDailyAction";
import { getToken } from "../store/reducers/authReducer";
import { getDailyTasksAction } from "../store/actions/getDailyTasksAction";
import { getAllTaskAction } from "../store/actions/getAllTaskAction";
import { deleteTaskAction } from "../store/actions/deleteTaskAction";

export default function TaskView({ setShowEditModal, setShowCommentModal }) {
	const allTasks = useSelector(getAllTasks);
	const allTasksLoading = useSelector(getAllTasksLoading);
	const dispatch = useDispatch();
	const token = useSelector(getToken);
	const [endedTasksView, setEndedTasksView] = useState(false);

	const addDailySuccess = useSelector(getAddDailySuccess);
	const addToDailyHandle = (id) => {
		dispatch(addTaskToDailyAction(id, token));
		setTimeout(() => {
			dispatch(getDailyTasksAction(token));
			dispatch(getAllTaskAction(token));
		}, 1000);
	};
	const deleteTaskHandler = (id) => {
		
		dispatch(deleteTaskAction(id, token));
		setTimeout(() => {
			dispatch(getAllTaskAction(token));
		}, 1000);
	};
	const endedTasks = useSelector(getEndedTasks);
	const endedTasksLoading = useSelector(getEndedTasksLoading);
	const endedTasksHaveError = useSelector(getEndedTasksHaveError);
	const endedTasksErrorMessage = useSelector(getEndedTasksErrorMessage);
	return (
		<div>
			<button className="change-button" onClick={() => setEndedTasksView(!endedTasksView)}>
				{!endedTasksView ? "Befejezett feladatok" : "jelenlegi feladatok"}
			</button>
			{endedTasksView ? (
				endedTasksLoading ? (
					<Spinner
						style={{ display: "block", margin: "auto" }}
						animation="border"
						role="status"
					>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				) : endedTasksHaveError ? (
					<span>{endedTasksErrorMessage}</span>
				) : endedTasks.length === 0 ? (
					<span className="no-task">Nincs feladat</span>
				) : (
					endedTasks.map((task) => (
						<SmallTaskBox
							key={task.id}
							task={task}
							editModalShow={setShowEditModal}
							commentModalShow={setShowCommentModal}
							addToDailyHandle={addToDailyHandle}
							deleteTaskHandler={deleteTaskHandler}
						></SmallTaskBox>
					))
				)
			) : !allTasksLoading && allTasks ? (
				allTasks.length === 0 ? (
					<span className="no-task">Nincs feladat</span>
				) : (
					<>
						{allTasks.map((task) => (
							<SmallTaskBox
								key={task.id}
								task={task}
								editModalShow={setShowEditModal}
								commentModalShow={setShowCommentModal}
								addToDailyHandle={addToDailyHandle}
								deleteTaskHandler={deleteTaskHandler}
							></SmallTaskBox>
						))}
					</>
				)
			) : (
				<Spinner
					style={{ display: "block", margin: "auto" }}
					animation="border"
					role="status"
				>
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			)}
		</div>
	);
}
