import axios from "axios";
import { CREATE_TASK_FAIL, CREATE_TASK_START, CREATE_TASK_SUCCESS } from "../constants/taskConstants";

export const createTaskAction =
	(title, description, priority, category, token) => async (dispatch) => {
		dispatch({ type: CREATE_TASK_START });
		try {
			const {data} = await axios.get(process.env.REACT_APP_API_URL+"/api/statuses",{headers: { 'Content-Type': 'application/json',Authorization: 'Bearer '+token}});
			await axios.post(process.env.REACT_APP_API_URL+"/api/tasks", {
				title,
				description,
				priority:parseInt(priority),
				category: "api/categories/" + category,
                status:"api/statuses/"+data[0].id
			},{headers:{
                'Content-Type': 'application/json',
                Authorization:"Bearer " + token
            }});
			dispatch({type:CREATE_TASK_SUCCESS});
		} catch (e) {
			dispatch({type:CREATE_TASK_FAIL});
			
		}
	};
