export const ALL_TASKS_LOADING = "ALL_TASKS_LOADING";
export const ALL_TASKS_ERROR = "ALL_TASKS_ERROR";
export const ALL_TASKS_LOADED = "ALL_TASKS_LOADED";

export const SET_CURRENT_TASK = "SET_CURRENT_TASK";
export const REMOVE_CURRENT_TASK = "REMOVE_CURRENT_TASK";

export const SEARCH_TASKS = "SEARCH_TASKS";
export const FILTER_TASKS = "FILTER_TASKS";
export const ORDER_TASKS = "ORDER_TASKS";

export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL";
export const REMOVE_UPDATE_TASK_DATA = "REMOVE_UPDATE_TASK_DATA";

export const DAILY_TASKS_LOADING = "DAILY_TASKS_LOADING";
export const DAILY_TASKS_LOADED = "DAILY_TASKS_LOADED";
export const DAILY_TASKS_FAIL = "DAILY_TASKS_FAIL";

export const CREATE_TASK_START = "CREATE_TASK_START";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAIL = "CREATE_TASK_FAIL";

export const ADD_TO_DAILY = "ADD_TO_DAILY";
export const ADD_TO_DAILY_SUCCESS = "ADD_TO_DAILY_SUCCESS";
export const ADD_TO_DAILY_ERROR = "ADD_TO_DAILY_ERROR";
export const REMOVE_ADD_TO_DAILY_DATA = "REMOVE_ADD_TO_DAILY_DATA";

export const REMOVE_FROM_DAILY = "REMOVE_FROM_DAILY";
export const REMOVE_FROM_DAILY_SUCCESS = "REMOVE_FROM_DAILY_SUCCESS";
export const REMOVE_FROM_DAILY_ERROR = "REMOVE_FROM_DAILY_ERROR";
export const REMOVE_REMOVE_FROM_DAILY_DATA = "REMOVE_REMOVE_FROM_DAILY_DATA";

export const SET_STATUS = "SET_STATUS";
export const SET_STATUS_SUCCESS = "SET_STATUS_SUCCESS";
export const SET_STATUS_ERROR = "SET_STATUS_ERROR";
export const REMOVE_SET_STATUS_DATA = "REMOVE_SET_STATUS_DATA";

export const ENDED_TASKS_LOADING = "ENDED_TASKS_LOADING";
export const ENDED_TASKS_ERROR = "ENDED_TASKS_ERROR";
export const ENDED_TASKS_LOADED = "ENDED_TASKS_LOADED";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";
export const REMOVE_DELETE_DATA="REMOVE_DELETE_DATA";
