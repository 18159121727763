import React from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { removeTaskFromDailyAction } from "../store/actions/removeTaskFromDailyAction";
import { getToken } from "../store/reducers/authReducer";
import { getDailyTasksAction } from "../store/actions/getDailyTasksAction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getCommentsAction } from "../store/actions/getCommentsAction";
import { currentTaskAction } from "../store/actions/currentTaskAction";

import ModeCommentIcon from "@mui/icons-material/ModeComment";

import CheckIcon from "@mui/icons-material/Check";
import classNames from "classnames";
import { getAllTaskAction } from "../store/actions/getAllTaskAction";

export default function BigTaskBox({
	task,
	editModalShow,
	commentModalShow,
	orderNumber,
	taskCount,
	setShowStatusModal,
	index,
	handleOrderChange
}) {
	const dispatch = useDispatch();
	const token = useSelector(getToken);
	const onRemove = () => {
		dispatch(removeTaskFromDailyAction(task.id, token));
		setTimeout(() => {
			dispatch(getDailyTasksAction(token));
			dispatch(getAllTaskAction(token));
		}, 1000);
	};
	const onCommentButtonClick = () => {
		dispatch(getCommentsAction(task.id, token));
		commentModalShow(true);
	};
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

	const onStatusButtonClick = () => {
		dispatch(currentTaskAction(task));
		setShowStatusModal(true);
	};
	return (
		<div className="big-task-box">
			<div className="content" >
				<div className="big-task-box-header">
					<span className="task-category">{task.category.title}</span>
					<h2>{task.title}</h2>
					<div className="button-container">
						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={renderTooltip("Megjegyzések megtekintése")}
						>
							<button onClick={() => onCommentButtonClick()}>
								<ModeCommentIcon style={{fontSize:"1.2rem"}}></ModeCommentIcon>
							</button>
						</OverlayTrigger>
						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={renderTooltip("Státusz állítása")}
						>
							<button
								style={{ color: "green" }}
								onClick={() => {
									onStatusButtonClick();
								}}
							>
								<CheckIcon style={{fontSize:"1.2rem"}}></CheckIcon>
							</button>
						</OverlayTrigger>

						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={renderTooltip("Eltávolítás a napi feladatok közül")}
						>
							<button style={{ color: "red" }} onClick={() => onRemove()}>
								<ClearIcon style={{fontSize:"1.2rem"}}></ClearIcon>
							</button>
						</OverlayTrigger>
					</div>
				</div>
				<p>{task.description}</p>
			</div>
			<div className={classNames("order-buttons",{"bottom":orderNumber===1})}>
				{orderNumber === 1 ? (
					""
				) : (
					<button>
						<ExpandLessIcon onClick={()=>handleOrderChange(index,-1)} style={{fontSize:"1.2rem"}}></ExpandLessIcon>
					</button>
				)}
				{orderNumber === taskCount ? (
					""
				) : (
					<button >
						<ExpandMoreIcon onClick={()=>handleOrderChange(index,1)} style={{fontSize:"1.2rem"}}></ExpandMoreIcon>
					</button>
				)}
			</div>{" "}
		</div>
	);
}
