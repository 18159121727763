import React, { useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getToken } from "../store/reducers/authReducer";
import { getCategories } from "../store/reducers/categoryReducer";
import { getCurrentTask } from "../store/reducers/taskReducer";

export default function TaskModal({ show, handleClose, handle }) {
	const task = useSelector(getCurrentTask);
	const categories = useSelector(getCategories);
	const token = useSelector(getToken);
	const title = useRef();
	const description = useRef();
	const priority = useRef();
	const category = useRef();

	const [validated,setValidated]=useState(false);

	return (
		<Modal scrollable={true} show={show} onHide={handleClose}>
			<Modal.Header className="modal-background" closeButton>
				{task?
				<Modal.Title>Feladat szerkesztése</Modal.Title>
				:<Modal.Title>Új feladat létrehozása</Modal.Title>}
			</Modal.Header>
			<Modal.Body className="modal-background">
				<Form noValidate validated={validated} onSubmit={(e)=>{
					e.preventDefault();
					const form=e.currentTarget;
					if(!form.checkValidity()){
						setValidated(true);
						return;
					}
					handle(
						title.current.value,
						description.current.value,
						priority.current.value,
						category.current.value
					)
				}}>
					<Form.Group controlId="validationCustom01">
						<Form.Label>Feladat címe</Form.Label>
						<Form.Control
							required
							ref={title}
							defaultValue={task ? task.title : ""}
							placeholder="Feladat címe"
						></Form.Control>
					</Form.Group>
					<Form.Group controlId="validationCustom02">
						<Form.Label>Feladat leírása</Form.Label>
						<Form.Control
							ref={description}
							as="textarea"
							rows={20}
							defaultValue={task ? task.description : ""}
							placeholder="Feladat leírása"
						></Form.Control>
					</Form.Group>
					<Form.Group controlId="validationCustom03">
						<Form.Label>Prioritás</Form.Label>
						<Form.Control
							ref={priority}
							min={1}
							max={5}
							defaultValue={task ? task.priority : 1}
							type="number"
							required
						></Form.Control>
						<Form.Control.Feedback type="invalid">
							Kérem 1 és 5 közötti értéket adjon meg
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="validationCustom04">
						<Form.Label>Kategória</Form.Label>
						<Form.Select
							required
							ref={category}
							defaultValue={task ? task.category.id : ""}
						>
							<option></option>
							{categories.map((category) => (
								<option key={category.id} value={category.id}>
									{category.title}
								</option>
							))}
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							Kérem válasszon egy kategóriát
						</Form.Control.Feedback>
					</Form.Group>
					{task ? (
					<Button
						variant="primary"
						type="submit"
					>
						Mentés
					</Button>
				) : (
					<Button
						variant="primary"
						type="submit"
					>
						Létrehoz
					</Button>
				)}
				</Form>
			</Modal.Body>
			<Modal.Footer className="modal-background">
				<Button variant="secondary" onClick={handleClose}>
					Bezárás
				</Button>
				
			</Modal.Footer>
		</Modal>
	);
}
