import axios from "axios";
import {
	UPDATE_TASK,
	UPDATE_TASK_FAIL,
	UPDATE_TASK_SUCCESS,
} from "../constants/taskConstants";


export const updateTaskAction =
	(task, title, description, priority, category, id, token) =>
	async (dispatch) => {
		dispatch({ type: UPDATE_TASK });
		
		try {
			await axios.patch(
				process.env.REACT_APP_API_URL+"/api/tasks/" + id,
				{
					...task,
					title: title,
					description: description,
					priority: parseInt(priority),
					category: "/api/categories/" + category,
					fulfillment_date: task.fulfillment_date,
					status: "/api/statuses/" + task.status.id,
					comments: [
						...task.comments.map((comment) => "/api/comments/" + comment.id),
					],
				},
				{
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type":"application/merge-patch+json",
					},
				}
			);
			dispatch({ type: UPDATE_TASK_SUCCESS });
		} catch (e) {
			dispatch({ type: UPDATE_TASK_FAIL });
			
		}
	};
