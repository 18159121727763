import React from "react";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router";
export default function AuthButtons({ windowSize }) {
	const navigate = useNavigate();
	const onClick = (route) => {
		navigate(route);
	};
	return (
		<>
			{windowSize > 750 ? (
				<>
					<button onClick={() => onClick("/login")}>
						Belépés <LoginIcon></LoginIcon>
					</button>
					<button onClick={() => onClick("/registration")}>Regisztráció</button>
				</>
			) : (
				<>
					<button onClick={() => onClick("/login")}>
						<LoginIcon></LoginIcon>
					</button>
					<button onClick={() => onClick("/registration")}>Regisztráció</button>
				</>
			)}
		</>
	);
}
