import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import MainPage from "./pages/MainPage";
import TaskPage from "./pages/TaskPage";
import Header from "./components/Header";
import { useEffect, useState } from "react";
import SettingsModal from "./components/SettingsModal";
import { useDispatch, useSelector } from "react-redux";
import { getIsDarkTheme } from "./store/reducers/settingsReducer";
import classNames from "classnames";

import TaskModal from "./components/TaskModal";
import { createTaskAction } from "./store/actions/createTaskAction";
import { getToken } from "./store/reducers/authReducer";
import { getAllTaskAction } from "./store/actions/getAllTaskAction";
import { getCategoriesAction } from "./store/actions/getCategoriesAction";
import {
	getAddDailyHaveError,
	getAddDailyMessage,
	getAddDailySuccess,
	getDeleteTaskHaveError,
	getDeleteTaskMessage,
	getDeleteTaskSuccess,
	getRemoveDailyHaveError,
	getRemoveDailyMessage,
	getRemoveDailySuccess,
	getSetStatusHaveError,
	getSetStatusMessage,
	getSetStatusSuccess,
} from "./store/reducers/taskReducer";
import { Alert } from "react-bootstrap";
import {
	getDeleteCategoryHaveError,
	getDeleteCategoryMessage,
	getDeleteCategorySuccess,
} from "./store/reducers/categoryReducer";

function App() {
	const [showSettingsModal, setShowSettingsModal] = useState(false);
	const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
	const isDarkTheme = useSelector(getIsDarkTheme);
	const dispatch = useDispatch();
	const token = useSelector(getToken);
	const handleCreate = (title, description, priority, category) => {
		dispatch(createTaskAction(title, description, priority, category, token));
		setShowCreateTaskModal(false);
		setTimeout(() => {
			dispatch(getAllTaskAction(token));
			dispatch(getCategoriesAction(token));
		}, 500);
	};

	const haveAddDailyError = useSelector(getAddDailyHaveError);
	const addDailyMessage = useSelector(getAddDailyMessage);
	const addDailySuccess = useSelector(getAddDailySuccess);

	const haveSetStatusError = useSelector(getSetStatusHaveError);
	const setStatusMessage = useSelector(getSetStatusMessage);
	const setStatusSuccess = useSelector(getSetStatusSuccess);

	const haveRemoveDailyError = useSelector(getRemoveDailyHaveError);
	const removeDailyMessage = useSelector(getRemoveDailyMessage);
	const removeDailySuccess = useSelector(getRemoveDailySuccess);

	const haveDeleteTaskError = useSelector(getDeleteTaskHaveError);
	const deleteTaskMessage = useSelector(getDeleteTaskMessage);
	const deleteTaskSuccess = useSelector(getDeleteTaskSuccess);

	const haveDeleteCategoryError = useSelector(getDeleteCategoryHaveError);
	const deleteCategoryMessage = useSelector(getDeleteCategoryMessage);
	const deleteCategorySuccess = useSelector(getDeleteCategorySuccess);

	

	return (
		<div className={classNames("App", { dark: isDarkTheme })}>
			<BrowserRouter>
				<Header
					setShowCreateTaskModal={setShowCreateTaskModal}
					setShowSettings={setShowSettingsModal}
				></Header>
				{haveAddDailyError ? (
					<Alert variant="danger">{addDailyMessage}</Alert>
				) : (
					""
				)}
				{addDailySuccess ? (
					<Alert variant="success">{addDailyMessage}</Alert>
				) : (
					""
				)}
				{haveSetStatusError ? (
					<Alert variant="danger">{setStatusMessage}</Alert>
				) : (
					""
				)}
				{setStatusSuccess ? (
					<Alert variant="success">{setStatusMessage}</Alert>
				) : (
					""
				)}
				{haveRemoveDailyError ? (
					<Alert variant="danger">{removeDailyMessage}</Alert>
				) : (
					""
				)}
				{removeDailySuccess ? (
					<Alert variant="success">{removeDailyMessage}</Alert>
				) : (
					""
				)}
				{haveDeleteTaskError ? (
					<Alert variant="danger">{deleteTaskMessage}</Alert>
				) : (
					""
				)}
				{deleteTaskSuccess ? (
					<Alert variant="success">{deleteTaskMessage}</Alert>
				) : (
					""
				)}
				{haveDeleteCategoryError ? (
					<Alert variant="danger">{deleteCategoryMessage}</Alert>
				) : (
					""
				)}
				{deleteCategorySuccess ? (
					<Alert variant="success">{deleteCategoryMessage}</Alert>
				) : (
					""
				)}
				<Routes>
					<Route exact path="/" element={<MainPage></MainPage>}></Route>
					<Route exact path="/login" element={<LoginPage></LoginPage>}></Route>
					<Route
						exact
						path="/registration"
						element={<RegistrationPage></RegistrationPage>}
					></Route>
					<Route exact path="/tasks" element={<TaskPage></TaskPage>} />
				</Routes>
			</BrowserRouter>
			<SettingsModal
				show={showSettingsModal}
				handleClose={() => setShowSettingsModal(false)}
			></SettingsModal>
			<TaskModal
				show={showCreateTaskModal}
				handleClose={() => setShowCreateTaskModal(false)}
				handle={handleCreate}
			></TaskModal>
		</div>
	);
}

export default App;
