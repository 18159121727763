import Cookies from "js-cookie";
import { DARK_THEME, LIGHT_THEME } from "../../themeTypes";
import { CHANGE_THEME } from "../constants/settingsConstants"

export const changeThemeAction=(isChecked)=> async (dispatch)=>{
    if(isChecked){
        Cookies.set("theme" ,LIGHT_THEME);
        
    }else{
        Cookies.set("theme",DARK_THEME);
    }
    dispatch({type:CHANGE_THEME});
}