import axios from "axios";

export const orderChangeAction =
	(taskId1, taskId2, token) => async (dispatch) => {
		try {
			await axios.get(
				process.env.REACT_APP_API_URL+`/api/tasks/${taskId1}/orderChange/${taskId2}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token,
					},
				}
			);
		} catch (e) {}
	};
