import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import { getLoggedIn } from "../store/reducers/authReducer";
import { useSelector } from "react-redux";
import AuthButtons from "./AuthButtons";

export default function BigNavButtons({
	windowSize,
	onLogout,
	setShowSettings,
	setShowCreateTaskModal
}) {
	const loggedIn = useSelector(getLoggedIn);
	return (
		<>
			{" "}
			{loggedIn ? (
				<>
					<button onClick={()=>setShowCreateTaskModal(true)}>
						Új feladat <AddIcon></AddIcon>
					</button>
					<button onClick={() => setShowSettings(true)}>
						Beállítások <SettingsIcon></SettingsIcon>
					</button>
					<button onClick={() => onLogout()}>
						Kilépés <LogoutIcon></LogoutIcon>
					</button>
				</>
			) : (
				<AuthButtons windowSize={windowSize}></AuthButtons>
			)}
		</>
	);
}
