import React, { useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function NewCategoryModal({ show, handleClose,handle }) {
	const [validated, setValidated] = useState(false);
    const title=useRef();
    const description=useRef();
	return (
		<Modal scrollable={true} show={show} onHide={handleClose}>
			<Modal.Header className="modal-background" closeButton>
				<Modal.Title>Új kategória létrehozása</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-background">
				<Form
					noValidate
					validated={validated}
					onSubmit={(e) => {
						e.preventDefault();
						const form = e.currentTarget;
						if (!form.checkValidity()) {
							setValidated(true);
							return;
						}
						handle(
							title.current.value,
							description.current.value,
						);
					}}
				>
					<Form.Group controlId="validationCustom01">
						<Form.Label>Kategória címe</Form.Label>
						<Form.Control
							required
							ref={title}
							placeholder="Kategória címe"
						></Form.Control>
					</Form.Group>
					<Form.Group controlId="validationCustom02">
						<Form.Label>Kategória leírása</Form.Label>
						<Form.Control
							ref={description}
							as="textarea"
							rows={20}
							placeholder="Kategória leírása"
						></Form.Control>
					</Form.Group>
					<Button type="submit">Létrehoz</Button>
				</Form>
			</Modal.Body>
			<Modal.Footer className="modal-background">
				<Button variant="secondary" onClick={handleClose}>
					Bezárás
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
