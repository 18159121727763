
import {
	ALL_TASKS_ERROR,
	ALL_TASKS_LOADED,
	ALL_TASKS_LOADING,
	ENDED_TASKS_ERROR,
	ENDED_TASKS_LOADED,
	ENDED_TASKS_LOADING,
} from "../constants/taskConstants";

import axios from "axios";

export const filterAction =
	(title, status, priority, category, titleOrder,priorityOrder,createdAtOrder, token) =>
	async (dispatch) => {
		let sendData = {};
		let {data2}={}
		try{
			const {data} = await axios.get(process.env.REACT_APP_API_URL+"/api/statuses",{headers: { 'Content-Type': 'application/json',Authorization: 'Bearer '+token}});
			data2=data;
			sendData.status =[data[0].id,data[1].id];
		}catch(e){
			dispatch({
				type: ALL_TASKS_ERROR,
				payload:
					"Valamilyen hiba lépett fel a betöltés során, próbáld meg később",
			});
			return;
		}

		sendData.status =[1,2];
		if(title){
			sendData.title = title;
		}
		if (status) {
			sendData.status = [parseInt(status)];
		}
		if (priority) {
			sendData.priority = parseInt(priority);
		}
		if (category) {
			sendData.category = parseInt(category);
		}
		if (titleOrder) {
			sendData = { ...sendData, "order[title]": titleOrder };
		}
		if (priorityOrder) {
			sendData = { ...sendData, "order[priority]": priorityOrder };
		}
		if (createdAtOrder) {
			sendData = { ...sendData, "order[createdAt]": createdAtOrder };
		}
		sendData.isDailyTask=false;
		
		try {
			dispatch({ type: ALL_TASKS_LOADING });
			const { data } = await axios.get(process.env.REACT_APP_API_URL+"/api/tasks", {
				params: sendData,
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			
			dispatch({ type: ALL_TASKS_LOADED, payload: data });
		} catch (e) {
			dispatch({
				type: ALL_TASKS_ERROR,
				payload:
					"Valamilyen hiba lépett fel a betöltés során, próbáld meg később",
			});
		}

		try {
			dispatch({ type: ENDED_TASKS_LOADING });
			const response = await axios.get(process.env.REACT_APP_API_URL+"/api/tasks", {
				params: {...sendData, status:[data2[2].id]},
				headers: { Authorization: "Bearer " + token },
			});
			dispatch({ type: ENDED_TASKS_LOADED, payload: response.data });
		} catch (exception) {
			dispatch({
				type: ENDED_TASKS_ERROR,
				payload:
					"Valamilyen hiba lépett fel a betöltés során, próbáld meg később",
			});
		}
		
	};
