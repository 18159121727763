import {
	GET_COMMENTS,
	GET_COMMENTS_FAIL,
	GET_COMMENTS_SUCCESS,
} from "../constants/commentConstants";

export const commentReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_COMMENTS:
			return { ...state, loading: true };
		case GET_COMMENTS_SUCCESS:
			return {
				...state,
				loading: false,
				currentComments: action.payload.comments,
				taskId: action.payload.taskId,
			};
		case GET_COMMENTS_FAIL:
			return {
				...state,
				loading: false,
				haveError: true,
				error: action.payload,
			};
		default:
			return state;
	}
};

export const getComments = (state) =>
	state ? (state.comment ? state.comment.currentComments : null) : null;
export const getTaskId = (state) =>
	state ? (state.comment ? state.comment.taskId : null) : null;
export const getLoading = (state) =>
	state ? (state.comment ? state.comment.loading : null) : null;
export const getHaveError = (state) =>
	state ? (state.comment ? state.comment.haveError : null) : null;
export const getError = (state) =>
	state ? (state.comment ? state.comment.error : null) : null;
