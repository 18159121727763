import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { loginAction } from "../store/actions/loginAction";
import { resetLoginDataAction } from "../store/actions/resetLoginDataAction";
import {
	getLoginErrorMessage,
	getLoginInProgress,
	getLoginSuccess,
} from "../store/reducers/authReducer";

export default function LoginPage() {
	const [validated, setValidated] = useState(false);
	const username = useRef();
	const password = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleLogin = (e) => {
		e.preventDefault();
		const form=e.currentTarget;
		if(!form.checkValidity()){
			setValidated(true);
			return;
		}
		dispatch(loginAction(username.current.value, password.current.value));
		
	};
	const loginInProgress = useSelector(getLoginInProgress);
	const loginSuccess = useSelector(getLoginSuccess);
	useEffect(() => {
		if (!loginInProgress && loginSuccess) {
			navigate("/tasks");
			dispatch(resetLoginDataAction());
		}
	}, [dispatch, loginInProgress, loginSuccess, navigate]);
	const error = useSelector(getLoginErrorMessage);
	return (
		<div id="login-page">
			<div id="login-container">
				<h1>Bejelentkezés</h1>
				<Form
					onSubmit={(e) => {
						handleLogin(e);
					}}
					validated={validated}
					noValidate
				>
					<Form.Group>
						<Form.Label>Email adress</Form.Label>
						<Form.Control
							type="email"
							placeholder="Enter email"
							htmlFor={username}
							ref={username}
							required
						/>
						<Form.Control.Feedback type="invalid">
							Nem felel meg az email formátuma. Adjon meg helyes email-t!
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" htmlFor={password} ref={password} />
					</Form.Group>
					<Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
					<button className="form-button" type="submit">
						Bejelentkezés
					</button>
				</Form>
			</div>
		</div>
	);
}
