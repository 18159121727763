import { CHANGE_THEME, CHANGE_VIEW_TYPE } from "../constants/settingsConstants";

export const settingsReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_VIEW_TYPE:
			return { ...state, viewType: action.payload };
		case CHANGE_THEME:
			return { ...state, darkTheme: !state.darkTheme };
		default:
			return state;
	}
};
export const getViewType = (state) =>
	state ? (state.settings ? state.settings.viewType : null) : null;
export const getIsDarkTheme = (state) =>
	state ? (state.settings ? state.settings.darkTheme : null) : null;
