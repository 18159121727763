import React, { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeStatusAction } from "../store/actions/changeStatusAction";
import { getAllTaskAction } from "../store/actions/getAllTaskAction";
import { getDailyTasksAction } from "../store/actions/getDailyTasksAction";
import { getToken } from "../store/reducers/authReducer";
import {
	getStatuses,
	getStatusesError,
	getStatusesHaveError,
	getStatusesLoading,
} from "../store/reducers/statusReducer";
import { getCurrentTask, getDailyTasks } from "../store/reducers/taskReducer";

export default function StatusModal({ show, handleClose }) {
	const statuses = useSelector(getStatuses);
	const statusesLoading = useSelector(getStatusesLoading);
	const statusesHaveError = useSelector(getStatusesHaveError);
	const statusesError = useSelector(getStatusesError);
	const status = useRef();
	const dispatch = useDispatch();
	const task = useSelector(getCurrentTask);
	const token = useSelector(getToken);
	const handleStatusChange = (e) => {
		e.preventDefault();
		dispatch(changeStatusAction(task.id, status.current.value, token));
		setTimeout(() => {
			dispatch(getDailyTasksAction(token));
			dispatch(getAllTaskAction(token));
		}, 500);
		handleClose();
	};
	return (
		<Modal scrollable={true} show={show} onHide={handleClose}>
			<Modal.Header className="modal-background" closeButton>
				<Modal.Title>Státusz módosítása</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-background">
				<Form onSubmit={(e) => handleStatusChange(e)}>
					<Form.Group style={{ marginBottom: "10px" }}>
						<Form.Label>Státusz</Form.Label>
						{!statusesLoading ? (
							statusesHaveError ? (
								statusesError
							) : (
								<>
									<Form.Select defaultValue={task?.status?.id} ref={status}>
										{statuses.map((status) => (
											<option key={status.id} value={status.id}>
												{status.name}
											</option>
										))}
									</Form.Select>
									<Form.Text>
										A folytatandó, vagy a befejezett státuszt adja meg ha le
										akarja zárni a napi feladatot
									</Form.Text>
								</>
							)
						) : (
							""
						)}
					</Form.Group>
					<Button type="submit">Státusz beállítása</Button>
				</Form>
			</Modal.Body>
			<Modal.Footer className="modal-background">
				<Button variant="secondary" onClick={() => handleClose()}>
					Bezárás
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
