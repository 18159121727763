
import {
	REGISTER_FAILURE,
	REGISTER_START,
	REGISTER_SUCCESS,
} from "../constants/authConstants";

import axios from "axios";

export const registerAction =
	(email, password, password2) => async (dispatch) => {
		try {
			dispatch({ type: REGISTER_START });
			await axios.post(
				process.env.REACT_APP_API_URL+"/api/registration",
				{},
				{
					params: {
						email: email,
						password: password,
						password2: password2,
					},
				}
			);
			dispatch({ type: REGISTER_SUCCESS });
		} catch (exception) {
			
			const { data } = exception.response;
			dispatch({ type: REGISTER_FAILURE, payload: {message:data.message,name:data.propName} });
		}
	};
