import axios from "axios";
import {
	ADD_TO_DAILY,
	ADD_TO_DAILY_ERROR,
	ADD_TO_DAILY_SUCCESS,
	REMOVE_ADD_TO_DAILY_DATA,
} from "../constants/taskConstants";

export const addTaskToDailyAction = (taskId, token) => async (dispatch) => {
	dispatch({ type: ADD_TO_DAILY });

	try {
		await axios.get(process.env.REACT_APP_API_URL+"/api/tasks/add/daily/" + taskId, {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		
		
		dispatch({ type: ADD_TO_DAILY_SUCCESS, payload: "Sikeres hozzáadás" });
		setTimeout(() => dispatch({ type: REMOVE_ADD_TO_DAILY_DATA }), 10000);
	} catch (e) {
		
		dispatch({ type: ADD_TO_DAILY_ERROR, payload: e.response.data.message });
		setTimeout(() => dispatch({ type: REMOVE_ADD_TO_DAILY_DATA }), 10000);
	}
};
