import axios from "axios";
import {
	REMOVE_SET_STATUS_DATA,
	SET_STATUS,
	SET_STATUS_ERROR,
	SET_STATUS_SUCCESS,
} from "../constants/taskConstants";

export const changeStatusAction =
	(taskId, statusId, token) => async (dispatch) => {
		dispatch({ type: SET_STATUS });
		try {
			await axios.get(
				process.env.REACT_APP_API_URL+`/api/tasks/${taskId}/status/${statusId}`,
				{ headers: { Authorization: "Bearer " + token } }
			);

			dispatch({ type: SET_STATUS_SUCCESS, payload: "Sikeres státusz változás"});
		} catch (e) {
			
			dispatch({ type: SET_STATUS_ERROR,payload:e.response.data.message });
		}
		setTimeout(() => {
			dispatch({ type: REMOVE_SET_STATUS_DATA });
		}, 10000);
	};
