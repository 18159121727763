import axios from "axios";
import { DAILY_TASKS_FAIL, DAILY_TASKS_LOADED, DAILY_TASKS_LOADING } from "../constants/taskConstants";

export const getDailyTasksAction = (token) => async (dispatch) => {
	dispatch({ type: DAILY_TASKS_LOADING });
	try {
		const {data}= await axios.get(process.env.REACT_APP_API_URL+"/api/tasks", {
			params: { isDailyTask: true, "order[dailyOrder]":"ASC" },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
        dispatch({type:DAILY_TASKS_LOADED,payload:data});
	} catch (e) {
        dispatch({type:DAILY_TASKS_FAIL, payload:"Valmilyen hiba lépett fel a betöltés során"})
    }
};
