import React, { useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createCommentAction } from "../store/actions/createCommentAction";
import { getCommentsAction } from "../store/actions/getCommentsAction";
import { getToken } from "../store/reducers/authReducer";
import {
	getComments,
	getError,
	getHaveError,
	getLoading,
	getTaskId,
} from "../store/reducers/commentReducer";
import { getCurrentTask } from "../store/reducers/taskReducer";

export default function CommentModal({ show, handleClose }) {
	const comments = useSelector(getComments);
	const loading = useSelector(getLoading);
	const haveError = useSelector(getHaveError);
	const error = useSelector(getError);
	const taskId = useSelector(getTaskId);
	const token = useSelector(getToken);
	const task = useSelector(getCurrentTask);

	const text = useRef();

	const dispatch = useDispatch();
	const [validated, setValidated] = useState(false);
	const handleCreate = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		if (!form.checkValidity()) {
			setValidated(true);
			return;
		}
		dispatch(createCommentAction(text.current.value, taskId, token));

		setTimeout(()=>dispatch(getCommentsAction(taskId,token)), 200);
	};
	return (
		<Modal scrollable={true} show={show} onHide={handleClose}>
			<Modal.Header className="modal-background" closeButton>
				<Modal.Title>Megjegyzések</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-background">
				{loading ? (
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				) : (
					<>
						{haveError ? (
							"error"
						) : (
							<>
								{comments.map((comment, index) => (
									<div key={index} className="comment-container">
										<p>{comment.text}</p>
										{index !== comments.length - 1 ? <hr /> : ""}
									</div>
								))}
								{task?.status?.name !== "Befejezett" ? (
									<Form
										noValidate
										validated={validated}
										onSubmit={(e) => handleCreate(e)}
									>
										<Form.Control
											as="textarea"
											rows={5}
											placeholder="Új komment hozzáadása"
											ref={text}
											required
										></Form.Control>
										<Button variant="secondary mt-3 mr-auto" type="submit">
											{" "}
											Hozzáad
										</Button>
									</Form>
								) : (
									""
								)}
							</>
						)}
					</>
				)}
			</Modal.Body>
			<Modal.Footer className="modal-background">
				<Button variant="secondary" onClick={handleClose}>
					Bezárás
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
