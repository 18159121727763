import { REMOVE_DATA, SET_DATA } from "../constants/orderAndFilterConstants";

export const orderAndFilterReducer = (state = {}, action) => {
	switch (action.type) {
		case SET_DATA:
			return { ...state, ...action.payload };
		case REMOVE_DATA:
			return {
				title: "",
				status: "",
				priority: "",
				category: "",
				priorityOrder: "",
				titleOrder: "",
				createdAtOrder: "",
			};

		default:
			return state;
	}
};
export const getTitle = (state) =>
	state
		? state.orderAndFilterData
			? state.orderAndFilterData.title
			: null
		: null;
export const getStatus = (state) =>
	state
		? state.orderAndFilterData
			? state.orderAndFilterData.status
			: null
		: null;
export const getPriority = (state) =>
	state
		? state.orderAndFilterData
			? state.orderAndFilterData.priority
			: null
		: null;
export const getCategory = (state) =>
	state
		? state.orderAndFilterData
			? state.orderAndFilterData.category
			: null
		: null;
export const getPriorityOrder = (state) =>
	state
		? state.orderAndFilterData
			? state.orderAndFilterData.priorityOrder
			: null
		: null;
export const getTitleOrder = (state) =>
	state
		? state.orderAndFilterData
			? state.orderAndFilterData.titleOrder
			: null
		: null;
export const getCreatedAtOrder = (state) =>
	state
		? state.orderAndFilterData
			? state.orderAndFilterData.createdAtOrder
			: null
		: null;
