import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function DeleteCategoryWarningModal({ show, handleClose,handleDelete }) {
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className="modal-background" closeButton>
				<Modal.Title>Kategória törlése</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-background">
				Ha törlöd a kategóriát akkor törlődnek a hozzá tartozó feladatok is.
				Biztosan törlöd a kategóriát? <br></br>
                <small style={{color:"red"}}>Ha van a napi feladatokhoz adott feladat a
				kategóriában akkor nem lehet törölni a kategóriát.</small>
			</Modal.Body>
			<Modal.Footer className="modal-background">
				<Button variant="secondary" onClick={handleClose}>
                    Vissza
				</Button>
				<Button variant="danger" onClick={()=>{handleDelete();handleClose()}}>
					Törlés
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
