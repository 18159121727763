import axios from "axios";

import {
	ALL_TASKS_ERROR,
	ALL_TASKS_LOADED,
	ALL_TASKS_LOADING,
} from "../constants/taskConstants";

export const getAllTaskAction = (token) => async (dispatch) => {
	try {
		dispatch({ type: ALL_TASKS_LOADING });
		const {data} = await axios.get(process.env.REACT_APP_API_URL+"/api/statuses",{headers: { 'Content-Type': 'application/json',Authorization: 'Bearer '+token}});
		const response = await axios.get(process.env.REACT_APP_API_URL+"/api/tasks", {
			params: {isDailyTask: false, status:[data[0].id,data[1].id]},
			headers: { Authorization: "Bearer " + token },
		});
		
		dispatch({ type: ALL_TASKS_LOADED, payload: response.data });
	} catch (exception) {
		dispatch({
			type: ALL_TASKS_ERROR,
			payload:
				"Valamilyen hiba lépett fel a betöltés során, próbáld meg később",
		});
	}
};
