import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import SmallNavButtons from "./SmallNavButtons";
import BigNavButtons from "./BigNavButtons";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../store/actions/logoutAction";
import classNames from "classnames";
import { getIsDarkTheme } from "../store/reducers/settingsReducer";
export default function Header({ setShowCreateTaskModal, setShowSettings }) {
	const navigation = useNavigate();
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const dispatch = useDispatch();
	const onLogout = () => {
		dispatch(logoutAction());
		navigation("/");
	};
	const isDarkTheme=useSelector(getIsDarkTheme);
	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize(window.innerWidth);
		};

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	});

	return (
		<nav className={classNames({"dark":isDarkTheme})}>
			<span id="main-title" onClick={() => navigation("/")}>
				IvyLee
			</span>
			<div id="nav-buttons">
				{windowSize > 750 ? (
					<BigNavButtons
						setShowSettings={setShowSettings}
						setShowCreateTaskModal={setShowCreateTaskModal}
						onLogout={onLogout}
						windowSize={windowSize}
					></BigNavButtons>
				) : (
					<SmallNavButtons
						setShowSettings={setShowSettings}
						setShowCreateTaskModal={setShowCreateTaskModal}
						onLogout={onLogout}
						windowSize={windowSize}
					></SmallNavButtons>
				)}
			</div>
		</nav>
	);
}
