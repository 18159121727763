import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeThemeAction } from "../store/actions/changeThemeAction";
import { getIsDarkTheme } from "../store/reducers/settingsReducer";

export default function SettingsModal({ show, handleClose }) {
  const dispatch=useDispatch();
	const onChangeTheme=()=>{
	dispatch(changeThemeAction(isDarkTheme));
  }
  const isDarkTheme=useSelector(getIsDarkTheme);
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header className="modal-background" closeButton>
				<Modal.Title>Beállítások</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Switch onChange={()=>onChangeTheme()} checked={isDarkTheme} label="Sötét Mód (Ki/Be)" ></Form.Switch>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer className="modal-background">
				<Button variant="secondary" onClick={handleClose}>
					Bezárás
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
