import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { currentTaskAction } from "../store/actions/currentTaskAction";
import { getCommentsAction } from "../store/actions/getCommentsAction";
import { getToken } from "../store/reducers/authReducer";

export default function ButtonContainer({
	task,
	editModalShow,
	commentModalShow,
	addToDailyHandle,
	deleteTaskHandler
}) {
	const token=useSelector(getToken);
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	const dispatch = useDispatch();
	const onEditButtonClick = () => {
		dispatch(currentTaskAction(task));
		editModalShow(true);
	};
	const onCommentButtonClick = () => {
		dispatch(getCommentsAction(task.id,token));
		dispatch(currentTaskAction(task));
		commentModalShow(true);
	};
	return (
		<div className="button-container">
			{task.status.name !== "Befejezett" ? (
				<OverlayTrigger
					placement="right"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip("Feladat szerkesztése")}
				>
					<button onClick={() => onEditButtonClick()}>
						<EditIcon style={{fontSize: "1.2rem"}}></EditIcon>
					</button>
				</OverlayTrigger>
			) : (
				""
			)}
			<OverlayTrigger
				placement="right"
				delay={{ show: 250, hide: 400 }}
				overlay={renderTooltip("Megjegyzések megtekintése")}
			>
				<button onClick={() => onCommentButtonClick()}>
					<ModeCommentIcon style={{fontSize: "1.2rem"}}></ModeCommentIcon>
				</button>
			</OverlayTrigger>
			{task.status.name !== "Befejezett" ? (
				<OverlayTrigger
					placement="right"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip("Hozzáadás a napi feladatokhoz")}
				>
					<button
						onClick={() => addToDailyHandle(task.id)}
						style={{ fontWeight: 900 }}
					>
						<AddIcon style={{ color: "green", fontWeight: 900, fontSize: "1.2rem" }}></AddIcon>
					</button>
				</OverlayTrigger>
			) : (
				""
			)}
			<OverlayTrigger
					placement="right"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip("Feladat törlése")}
				>
					<button onClick={()=>deleteTaskHandler(task.id)} style={{ color: "red" }} >
								<ClearIcon style={{fontSize:"1.2rem"}}></ClearIcon>
							</button>
				</OverlayTrigger>
		</div>
	);
}
