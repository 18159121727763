import axios from "axios";

export const createCategoryAction =
	(title, description, token) => async (dispatch) => {
		axios.post(
			process.env.REACT_APP_API_URL+"/api/categories",
			{ title, description },
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			}
		);
	};
