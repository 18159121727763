import axios from "axios";
import { DELETE_CATEGORY, DELETE_CATEGORY_FAIL, DELETE_CATEGORY_SUCCESS, REMOVE_DELETE_CATEGORY_DATA } from "../constants/categoryConstants";

export const deleteCategoryAction = (id,token) => async (dispatch) => {
    dispatch({type:DELETE_CATEGORY});
	try {
		await axios.delete(
			process.env.REACT_APP_API_URL + `/api/categories/${id}/delete`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			}
		);
        dispatch({type:DELETE_CATEGORY_SUCCESS,payload:"Sikeres eltávolítás"});
	} catch (e) {
        dispatch({type:DELETE_CATEGORY_FAIL,payload:e.response.data.message});
    }

    setTimeout(() => dispatch({ type: REMOVE_DELETE_CATEGORY_DATA }), 8000);

};
