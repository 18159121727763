import axios from "axios";

export const createCommentAction =
	(comment, taskId, token) => async (dispatch) => {
        try{
		await axios.post(
			process.env.REACT_APP_API_URL+"/api/comments",
			{
				text: comment,
				task: "api/tasks/" + taskId,
			},
			{ headers: { Authorization: "Bearer " + token } }
		);
        }catch(e){
            console.log(e);
        }
	};
