import axios from "axios";
import { ALL_TASKS_ERROR, ALL_TASKS_LOADED, ALL_TASKS_LOADING } from "../constants/taskConstants";

export const searchAction = (text, token) => async (dispatch) => {
    dispatch({type:ALL_TASKS_LOADING});
    try{
	const {data}=await axios.get(process.env.REACT_APP_API_URL+"/api/tasks", {
		params: {
			title: text,
		},
        headers:{
            Authorization: "Bearer " + token
        }
	});
    dispatch({type:ALL_TASKS_LOADED,payload:data})
}catch(e){
   
    dispatch({type:ALL_TASKS_ERROR,payload:"Valamilyen hiba lépett fel a betöltés során, próbáld meg később"});
}
};
