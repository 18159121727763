import React, { useRef, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GridViewIcon from "@mui/icons-material/GridView";
import SortIcon from "@mui/icons-material/Sort";
import TaskView from "../components/TaskView";
import { useDispatch, useSelector } from "react-redux";
import { changeViewTypeAction } from "../store/actions/changeViewTypeAction";
import { getViewType } from "../store/reducers/settingsReducer";
import { TASK_VIEW } from "../viewTypes";
import CategoryView from "./CategoryView";
import Filter from "./Filter";
import { searchAction } from "../store/actions/searchAction";
import { getToken } from "../store/reducers/authReducer";
import { orderAndFilterSetDataAction } from "../store/actions/orderAndFilterSetDataAction";

export default function AllTasks({ setShowEditModal, setShowCommentModal }) {
	const dispatch = useDispatch();
	const onViewChange = () => {
		dispatch(changeViewTypeAction());
	};
	const onFilterClick=()=>{
		if(filterOpen){
			setFilterOpen(false);
			
		}else{
			setFilterOpen(true);
			
		}
	}
	
	const viewType = useSelector(getViewType);
	const [filterOpen, setFilterOpen] = useState(false);
	
	const text=useRef();
	const token=useSelector(getToken);
	const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
	const onSearch=(e)=>{
		e.preventDefault();
		dispatch(searchAction(text.current.value,token))
	}
	const onChange=(e,name)=>{
		dispatch(orderAndFilterSetDataAction({[name]:e.target.value}))
	}
	return (
		<div>
			<Form className="task-search-form" onSubmit={(e)=>onSearch(e)}>
				<Form.Control placeholder="Keresés" ref={text}></Form.Control>
			</Form>
			<div className="left-panel-button-container">
				<OverlayTrigger
					placement="right"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip("Szűrés")}
					
				>
					<button onClick={() => {onFilterClick()}}>
						<FilterAltIcon></FilterAltIcon>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="right"
					delay={{ show: 250, hide: 400 }}
					overlay={renderTooltip("Nézet váltás")}
				>
					<button onClick={() => onViewChange()}>
						<GridViewIcon></GridViewIcon>
					</button>
				</OverlayTrigger>
				
			</div>
			{filterOpen ? <Filter setFilterOpen={setFilterOpen} onChange={onChange} ></Filter> : ""}
			
			{viewType === TASK_VIEW ? (
				<TaskView
					setShowEditModal={setShowEditModal}
					setShowCommentModal={setShowCommentModal}
				></TaskView>
			) : (
				<CategoryView></CategoryView>
			)}
		</div>
	);
}
