import axios from "axios";
import { DELETE_TASK, DELETE_TASK_FAIL, DELETE_TASK_SUCCESS, REMOVE_DELETE_DATA } from "../constants/taskConstants";

export const deleteTaskAction=(taskId,token)=>async (dispatch)=>{
    dispatch({ type: DELETE_TASK });

	try {
		await axios.delete(process.env.REACT_APP_API_URL+"/api/tasks/" + taskId, {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
		dispatch({ type: DELETE_TASK_SUCCESS, payload: "Sikeres törlés" });
		setTimeout(() => dispatch({ type: REMOVE_DELETE_DATA }), 10000);
	} catch (e) {
		
		dispatch({ type: DELETE_TASK_FAIL, payload: e.response.data.message });
		setTimeout(() => dispatch({ type: REMOVE_DELETE_DATA }), 10000);
	}
}