import React, { useEffect, useState } from "react";

import { Offcanvas, Spinner } from "react-bootstrap";

import BigTaskBox from "../components/BigTaskBox";

import CommentModal from "../components/CommentModal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import { getAllTaskAction } from "../store/actions/getAllTaskAction";

import { getCategoriesAction } from "../store/actions/getCategoriesAction";

import AllTasks from "../components/AllTasks";
import { getLoggedIn, getToken } from "../store/reducers/authReducer";
import { useNavigate } from "react-router";
import { updateTaskAction } from "../store/actions/updateTaskAction";
import { getDailyTasksAction } from "../store/actions/getDailyTasksAction";
import {
	getCurrentTask,
	getDailyTasks,
	getDailyTasksError,
	getDailyTasksLoading,
	getDailyTasksSuccess,
} from "../store/reducers/taskReducer";
import TaskModal from "../components/TaskModal";
import { removeCurrentTaskAction } from "../store/actions/removeCurrentTaskAction";
import { getStatusesAction } from "../store/actions/getStatusesAction";
import StatusModal from "../components/StatusModal";
import { orderChangeAction } from "../store/actions/orderChangeAction";
import { getEndedTasksAction } from "../store/actions/getEndedTasksAction";

export default function TaskPage() {
	//state data
	const [showEditModal, setShowEditModal] = useState(false);
	const [showCommentModal, setShowCommentModal] = useState(false);
	const [showSideBar, setShowSideBar] = useState(false);
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const [showStatusModal, setShowStatusModal] = useState(false);

	const loggedIn = useSelector(getLoggedIn);
	const token = useSelector(getToken);

	//daily task data
	const dailyTasks = useSelector(getDailyTasks);
	const dailyTasksLoading = useSelector(getDailyTasksLoading);
	const dailyTasksSuccess = useSelector(getDailyTasksSuccess);
	const dailyTasksError = useSelector(getDailyTasksError);
	const currentTask = useSelector(getCurrentTask);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleOrderChange=(index, direction)=>{

		
		dispatch(orderChangeAction(dailyTasks[index].id, dailyTasks[(index+direction)].id, token));
		setTimeout(()=>{dispatch(getDailyTasksAction(token))},500);
	}
	const handleUpdate = (title, description, priority, category) => {
		dispatch(
			updateTaskAction(
				currentTask,
				title,
				description,
				priority,
				category,
				currentTask.id,
				token
			)
		);
		setShowEditModal(false);
		dispatch(removeCurrentTaskAction());
		setTimeout(() => {
			dispatch(getAllTaskAction(token));
			dispatch(getCategoriesAction(token));
		}, 500);
	};
	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize(window.innerWidth);
		};

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	});

	useEffect(() => {
		if (!loggedIn) {
			navigate("/login");
			return;
		}
		dispatch(getAllTaskAction(token));
		dispatch(getCategoriesAction(token));
		dispatch(getDailyTasksAction(token));
		dispatch(getStatusesAction(token));
		dispatch(getEndedTasksAction(token));
	}, [dispatch, token, loggedIn, navigate]);

	
	return (
		<div className="grid-container">
			{windowSize > 1100 ? (
				<AllTasks
					setShowEditModal={setShowEditModal}
					setShowCommentModal={setShowCommentModal}
				></AllTasks>
			) : (
				<>
					<div onClick={() => setShowSideBar(true)} className="sidebar-opener">
						{" "}
						<ArrowForwardIosIcon></ArrowForwardIosIcon>
					</div>
					<Offcanvas show={showSideBar} onHide={() => setShowSideBar(false)}>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title></Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<AllTasks
								setShowEditModal={setShowEditModal}
								setShowCommentModal={setShowCommentModal}
							></AllTasks>
						</Offcanvas.Body>
					</Offcanvas>
				</>
			)}

			<div id="right-container">
				{dailyTasksLoading ? (
					<Spinner
						style={{ display: "block", margin: "auto" }}
						animation="border"
						role="status"
					>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				) : dailyTasksSuccess ? (
					dailyTasks.length === 0 ? (
						<span className="no-daily-task">Nincs napi feladat</span>
					) : (
						dailyTasks.map((task, index) => (
							<BigTaskBox
								key={task.id}
								task={task}
								editModalShow={setShowEditModal}
								commentModalShow={setShowCommentModal}
								orderNumber={index + 1}
								taskCount={dailyTasks.length}
								setShowStatusModal={setShowStatusModal}
								index={index}
								handleOrderChange={handleOrderChange}
							></BigTaskBox>
						))
					)
				) : (
					<span>{dailyTasksError}</span>
				)}
			</div>
			<TaskModal
				show={showEditModal}
				handleClose={() => {
					dispatch(removeCurrentTaskAction());
					setShowEditModal(false);
				}}
				handle={handleUpdate}
			></TaskModal>
			<CommentModal
				show={showCommentModal}
				handleClose={() => setShowCommentModal(false)}
				comments={currentTask?.comments}
			></CommentModal>
			<StatusModal
				show={showStatusModal}
				handleClose={() => {
					setShowStatusModal(false);
					dispatch(removeCurrentTaskAction());
				}}
			></StatusModal>
		</div>
	);
}
