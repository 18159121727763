import {
	DELETE_CATEGORY,
	DELETE_CATEGORY_FAIL,
	DELETE_CATEGORY_SUCCESS,
	GET_ALL_CATEGORIES,
	GET_CATEGORIES_FAIL,
	GET_CATEGORIES_SUCCESS,
	REMOVE_DELETE_CATEGORY_DATA,
} from "../constants/categoryConstants";

export const categoryReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_CATEGORIES:
			return { ...state, loading: true };
		case GET_CATEGORIES_SUCCESS:
			return {
				...state,
				loading: false,
				allCategory: action.payload,
			};
		case GET_CATEGORIES_FAIL:
			return {
				...state,
				loading: false,
				haveError: true,
				error: action.payload,
			};
		case DELETE_CATEGORY:
			return {
				...state,
				deleteCategory: {
					...state.deleteCategory,
					loading: true,
				},
			};
		case DELETE_CATEGORY_SUCCESS:
			return {
				...state,
				deleteCategory: {
					...state.deleteCategory,
					loading: false,
					success: true,
					message: action.payload,
				},
			};
		case DELETE_CATEGORY_FAIL:
			return {
				...state,
				deleteCategory: {
					...state.deleteCategory,
					loading: false,
					haveError: true,
					message: action.payload,
				},
			};
		case REMOVE_DELETE_CATEGORY_DATA:
			return {
				...state,
				deleteCategory: {
					loading: false,
					success: false,
					haveError: false,
					message: null,
				},
			};
		default:
			return state;
	}
};

export const getCategories = (state) =>
	state ? (state.category ? state.category.allCategory : null) : null;

export const getDeleteCategoryLoading = (state) =>
	state
		? state.category
			? state.category.deleteCategory
				? state.category.deleteCategory.loading
				: null
			: null
		: null;
export const getDeleteCategorySuccess = (state) =>
	state
		? state.category
			? state.category.deleteCategory
				? state.category.deleteCategory.success
				: null
			: null
		: null;
export const getDeleteCategoryHaveError = (state) =>
	state
		? state.category
			? state.category.deleteCategory
				? state.category.deleteCategory.haveError
				: null
			: null
		: null;
export const getDeleteCategoryMessage = (state) =>
	state
		? state.category
			? state.category.deleteCategory
				? state.category.deleteCategory.message
				: null
			: null
		: null;
