import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { registerAction } from "../store/actions/registerAction";
import { resetRegisterDataAction } from "../store/actions/resetRegisterDataAction";
import {
	getRegisterInProgress,
	getRegisterSuccess,
} from "../store/reducers/authReducer";

export default function RegistrationPage() {
	const [validated, setValidated] = useState(false);
	const username = useRef();
	const password = useRef();
	const password2 = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleRegister = (e) => {
		e.preventDefault();
		const form=e.currentTarget;
		if(!form.checkValidity()){
			setValidated(true);
			return;
		}
		dispatch(
			registerAction(
				username.current.value,
				password.current.value,
				password2.current.value
			)
		);
		
	};
	const registerInProgress = useSelector(getRegisterInProgress);
	const registerSuccess = useSelector(getRegisterSuccess);
	useEffect(() => {
		if (!registerInProgress && registerSuccess) {
			navigate("/login");
			dispatch(resetRegisterDataAction());
		}
	}, [registerInProgress]);
	return (
		<div id="registration-page">
			<div id="registration-container">
				<h1>Regisztráció</h1>
				<Form
					validated={validated}
					noValidate
					onSubmit={(e) => handleRegister(e)}
				>
					<Form.Group>
						<Form.Label>Email cím</Form.Label>
						<Form.Control
							required
							ref={username}
							type="email"
							placeholder="Írja be az email címét"
							htmlFor={username}
						/>
						<Form.Control.Feedback type="invalid">
							Nem felel meg az email formátuma. Adjon meg helyes email-t!
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group>
						<Form.Label>Jelszó</Form.Label>
						<Form.Control required ref={password} type="password" htmlFor={password} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Jelszó újra</Form.Label>
						<Form.Control required ref={password2} type="password" />
					</Form.Group>
					<button type="submit" className="form-button">
						Regisztráció
					</button>
				</Form>
			</div>
		</div>
	);
}
