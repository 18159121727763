import axios from "axios";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { taskReducer } from "./reducers/taskReducer";
import Cookies from "js-cookie";
import { authReducer } from "./reducers/authReducer";
import { categoryReducer } from "./reducers/categoryReducer";
import { commentReducer } from "./reducers/commentReducer";
import { settingsReducer } from "./reducers/settingsReducer";
import { TASK_VIEW } from "../viewTypes";
import { statusReducer } from "./reducers/statusReducer";
import { DARK_THEME } from "../themeTypes";
import { orderAndFilterReducer } from "./reducers/orderAndFilterReducer";


export const axiosInstance = axios.create({
	baseURL: "https://localhost:8000/api/",
	headers: {
        "Content-Type": "application/json"
	},
});

const initialState = {
	task: {
		endedTasks:{
			loading:false,
			haveError:false,
			errorMessage:null,
			tasks:[]
		},
		allTasks: {
			loading: false,
			haveError: false,
			errorMessage: null,
			tasks: [],
		},
		dailyTasks:{
			loading:false,
			success:false,
			error:null,
			tasks:[],
		},
		currentTask: null,
		updateTask:{
			loading:false,
			success:false,
			error:false,
		},
		createTask:{
			started:false,
			success:false,
			end:false,
			errors:[],
		},
		addDaily:{
			started:false,
			success:false,
			haveError:false,
			message:null,
		},
		removeDaily:{
			started:false,
			success:false,
			haveError:false,
			message:null,
		},
		setStatus:{
			started:false,
			success:false,
			haveError:false,
			message:null,
		},
		deleteTask:{
			loading:false,
			success:false,
			haveError:false,
			message:null,			
		},
		
	},
    category:{
        allCategory:[],
        loading:false,
        haveError:false,
        error:"",
		deleteCategory:{
			loading:false,
			success:false,
			haveError:false,
			message:null,			
		}
    },
	comment:{
		currentComments:[],
		taskId:null,
		loading:false,
		haveError:false,
		error:""
	},
	settings:{
		viewType: Cookies.get("viewType")? Cookies.get("viewType"):TASK_VIEW,
		darkTheme:Cookies.get("theme") && Cookies.get("theme")===DARK_THEME?true:false ,
	},
	status:{
		loading:false,
		success:false,
		haveError:false,
		error:"",
		statuses:[],
	},
	orderAndFilterData:{
		title:"",
		status:"",
		priority:"",
		category:"",
		priorityOrder:"",
		titleOrder:"",
		createdAtOrder:"",
	},
	auth: {
		loginInProgress: false,
		loginSuccess: false,
		loginError: false,
		loginErrorMessage: null,
		loggedIn:Cookies.get("token")? true:false,
		token: Cookies.get("token")? Cookies.get("token"):"",
		registerSuccess: false,
		registerInProgress: false,
		registerError: false,
		registerErrorMessage: null,
	},

};

const reducer = combineReducers({
	task: taskReducer,
    category: categoryReducer,
	comment:commentReducer,
	settings:settingsReducer,
	status:statusReducer,
	orderAndFilterData:orderAndFilterReducer,
	auth: authReducer,
});

const composeEnchancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducer,
	initialState,
	composeEnchancer(applyMiddleware(thunk))
);

export default store;
