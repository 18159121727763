import axios from "axios";
import { REMOVE_FROM_DAILY, REMOVE_FROM_DAILY_ERROR, REMOVE_FROM_DAILY_SUCCESS, REMOVE_REMOVE_FROM_DAILY_DATA } from "../constants/taskConstants"

export const removeTaskFromDailyAction=(taskId,token)=>async (dispatch)=>{
    dispatch({type:REMOVE_FROM_DAILY});
    try{
        await axios.get(process.env.REACT_APP_API_URL+"/api/tasks/remove/daily/" + taskId, {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});
        dispatch({type:REMOVE_FROM_DAILY_SUCCESS});
        setTimeout(() => dispatch({ type: REMOVE_REMOVE_FROM_DAILY_DATA }), 10000);
    }catch(e){
        dispatch({type:REMOVE_FROM_DAILY_ERROR,payload:e.response.data.message});
        setTimeout(() => dispatch({ type: REMOVE_REMOVE_FROM_DAILY_DATA }), 10000);
    }
}