import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../store/reducers/categoryReducer";
import AddIcon from "@mui/icons-material/Add";
import NewCategoryModal from "./NewCategoryModal";
import { createCategoryAction } from "../store/actions/createCategoryAction";
import { getToken } from "../store/reducers/authReducer";
import { getCategoriesAction } from "../store/actions/getCategoriesAction";

import ClearIcon from '@mui/icons-material/Clear';

import { deleteCategoryAction } from "../store/actions/deleteCategoryAction";
import DeleteCategoryWarningModal from "./DeleteCategoryWarningModal";
import { getAllTaskAction } from "../store/actions/getAllTaskAction";
import { getEndedTasksAction } from "../store/actions/getEndedTasksAction";

export default function CategoryView() {
	const categories = useSelector(getCategories);
    const token=useSelector(getToken);
    const [showNewCategoryModal,setShowNewCategoryModal] = useState(false);
    const [showDeleteCategoryWarningModal,setShowDeleteCategoryWarningModal] = useState(false);
    const dispatch=useDispatch();
    const [currentCategoryId,setCurrentCategoryId] = useState();
    const handleCreateCategory=(title,description)=>{
        dispatch(createCategoryAction(title,description,token))
        setTimeout(()=>dispatch(getCategoriesAction(token)),200);
        setShowNewCategoryModal(false);

    }
    const handleDeleteCategory=()=>{
        dispatch(deleteCategoryAction(currentCategoryId,token));
        setTimeout(()=>dispatch(getCategoriesAction(token)),500);
        setTimeout(()=>dispatch(getAllTaskAction(token)),500);
        setTimeout(()=>dispatch(getEndedTasksAction(token)),500);
    }
	return (<>
		<Accordion alwaysOpen>
            {categories.map(category=>{
                return (<Accordion.Item eventKey={category.id} key={category.id}>
                    <Accordion.Header>{category.title}</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            {category.description}
                        </p>
                        {category.tasks.map((task)=>{
                            return (<div className="accordion-task" key={task.id}>
                                {task.title}
                                
                            </div>)
                        })}
                        <button onClick={()=>{setShowDeleteCategoryWarningModal(true); setCurrentCategoryId(category.id)}} className="category-delete-button">Törlés<ClearIcon style={{fontSize:"1.5rem",color:"red"}}></ClearIcon></button>
                    </Accordion.Body>
                </Accordion.Item>)
            })}
            <button onClick={()=>setShowNewCategoryModal(true)} className="new-category-btn">Új kategória <AddIcon></AddIcon></button>
		</Accordion>
        <NewCategoryModal show={showNewCategoryModal} handleClose={()=>setShowNewCategoryModal(false)} handle={handleCreateCategory}></NewCategoryModal>
        <DeleteCategoryWarningModal show={showDeleteCategoryWarningModal} handleClose={()=>setShowDeleteCategoryWarningModal(false)} handleDelete={handleDeleteCategory}></DeleteCategoryWarningModal> 
        </>
	);
}
