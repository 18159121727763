import axios from "axios";
import { ENDED_TASKS_ERROR, ENDED_TASKS_LOADED, ENDED_TASKS_LOADING } from "../constants/taskConstants";

export const getEndedTasksAction=(token)=>async (dispatch)=>{

    try {
		dispatch({ type: ENDED_TASKS_LOADING });
		const {data} = await axios.get(process.env.REACT_APP_API_URL+"/api/statuses",{headers: { 'Content-Type': 'application/json',Authorization: 'Bearer '+token}});
		const response = await axios.get(process.env.REACT_APP_API_URL+"/api/tasks", {
			params: {isDailyTask: false, status:[data[2].id]},
			headers: { Authorization: "Bearer " + token },
		});
		dispatch({ type: ENDED_TASKS_LOADED, payload: response.data });
	} catch (exception) {
		dispatch({
			type: ENDED_TASKS_ERROR,
			payload:
				"Valamilyen hiba lépett fel a betöltés során, próbáld meg később",
		});
	}
} 