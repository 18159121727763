import {
	LOGIN_FAILURE,
	LOGIN_START,
	LOGIN_SUCCESS,
	LOG_OUT,
	REGISTER_FAILURE,
	REGISTER_START,
	REGISTER_SUCCESS,
	RESET_LOGIN_DATA,
	RESET_REGISTER_DATA,
} from "../constants/authConstants";

export const authReducer = (state = {}, action) => {
	switch (action.type) {
		case LOGIN_START:
			return { ...state, loginInProgress: true };
		case LOGIN_SUCCESS:
			return {
				...state,
				loginInProgress: false,
				loginSuccess: true,
				loginError: false,
				loginErrorMessage: null,
				loggedIn: true,
				token: action.payload,
			};
		case LOGIN_FAILURE:
			return {
				...state,
				loginInProgress: false,
				loginError: true,
				loginErrorMessage: action.payload,
			};
		case RESET_LOGIN_DATA:
			return {
				...state,
				loginInProgress: false,
				loginSuccess: false,
				loginError: false,
				loginErrorMessage: null,
			};
		case REGISTER_START:
			return { ...state, registerInProgress: true };

		case REGISTER_SUCCESS:
			return {
				...state,
				registerInProgress: false,
				registerSuccess: true,
				registerError: false,
				registerErrorMessage: null,
			};
		case REGISTER_FAILURE:
			return {
				...state,
				registerInProgress: false,
				registerError: true,
				registerErrorMessage: action.payload,
			};
		case RESET_REGISTER_DATA:
			return {
				...state,
				registerInProgress: false,
				registerSuccess: false,
				registerError: false,
				registerErrorMessage: null,
			};
		case LOG_OUT:
			return { ...state, loggedIn: false };
		default:
			return state;
	}
};

export const getLoginInProgress = (state) =>
	state ? (state.auth ? state.auth.loginInProgress : null) : null;
export const getLoginSuccess = (state) =>
	state ? (state.auth ? state.auth.loginSuccess : null) : null;
export const getLoginFailure = (state) =>
	state ? (state.auth ? state.auth.loginError : null) : null;
export const getLoginErrorMessage = (state) =>
	state ? (state.auth ? state.auth.loginErrorMessage : null) : null;
export const getLoggedIn = (state) =>
	state ? (state.auth ? state.auth.loggedIn : null) : null;
export const getToken = (state) =>
	state ? (state.auth ? state.auth.token : null) : null;

export const getRegisterInProgress = (state) =>
	state ? (state.auth ? state.auth.registerInProgress : null) : null;
export const getRegisterSuccess = (state) =>
	state ? (state.auth ? state.auth.registerSuccess : null) : null;
export const getRegisterFailure = (state) =>
	state ? (state.auth ? state.auth.registerError : null) : null;
export const getRegisterErrorMessage = (state) =>
	state ? (state.auth ? state.auth.registerErrorMessage : null) : null;
