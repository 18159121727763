
import Cookies from 'js-cookie'
import { LOGIN_FAILURE, LOGIN_START, LOGIN_SUCCESS } from "../constants/authConstants";
import axios from "axios";
export const loginAction =
	( email, password ) =>
	async (dispatch) => {
		try {
			dispatch({type: LOGIN_START});
			const {data}=await axios.post(process.env.REACT_APP_API_URL+"/login", {
				username: email,
				password: password,
			});

            Cookies.set("token",data.token);
			dispatch({type:LOGIN_SUCCESS, payload:data.token});
		} catch (exception) {
			const {data}=exception.response;
			dispatch({type:LOGIN_FAILURE,payload:data.error})
        }
	};
