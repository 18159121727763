import Cookies from "js-cookie"
import { CATEGORY_VIEW, TASK_VIEW } from "../../viewTypes";
import { CHANGE_VIEW_TYPE } from "../constants/settingsConstants";

export const changeViewTypeAction = (viewType)=> async (dispatch)=>{
    const viewType=Cookies.get("viewType");
    if(viewType===TASK_VIEW){
        Cookies.set("viewType",CATEGORY_VIEW);
        dispatch({type:CHANGE_VIEW_TYPE,payload:CATEGORY_VIEW})
    }else{
        Cookies.set("viewType",TASK_VIEW);
        dispatch({type:CHANGE_VIEW_TYPE,payload:TASK_VIEW})
    }
} 