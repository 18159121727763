import axios from "axios";


import { GET_COMMENTS, GET_COMMENTS_FAIL, GET_COMMENTS_SUCCESS } from "../constants/commentConstants";


export const getCommentsAction=(taskId,token)=>async (dispatch)=>{
    try {
		dispatch({type:GET_COMMENTS});
		const response=await axios.get(process.env.REACT_APP_API_URL+"/api/comments", {
			params: {
                task:taskId
			},
			headers: {
				Authorization: "Bearer " + token
			}
		});
		dispatch({type:GET_COMMENTS_SUCCESS, payload:{comments:response.data, taskId:taskId}});
		
	} catch (exception) {
        
		dispatch({type:GET_COMMENTS_FAIL,payload:"Valamilyen hiba lépett fel a betöltés során, próbáld meg később"});
    }

} 